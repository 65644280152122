<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                        <b-row>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Fiscal year" vid="fiscal_year_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="fiscal_year_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="scoreSetupData.fiscal_year_id"
                                                        :options="fiscalYearList"
                                                        id="fiscal_year_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Academic Records" vid="academic_records" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="academic_records"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('barc_config.academic_records') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        id="academic_records"
                                                        v-model="scoreSetupData.academic_records"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Service Length" vid="service_length" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="service_length"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('barc_config.service_length') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        id="hatt_name_bn"
                                                        v-model="scoreSetupData.service_length"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Age" vid="age" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="age"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('barc_config.age') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        id="hatt_name_bn"
                                                        v-model="scoreSetupData.age"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Publication" vid="publications" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="publications"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('barc_config.publications') }} <span class="text-danger">*</span>
                                                        </template>
                                                    <b-form-input
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        id="hatt_name_bn"
                                                        v-model="scoreSetupData.publications"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Foundation Training" vid="foundation_training" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="foundation_training"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('barc_config.foundation_training')}} <span class="text-danger">*</span>
                                                    </template>
                                                        <b-form-input
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        id="foundation_training"
                                                        v-model="scoreSetupData.foundation_training"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="ACR" vid="acr" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="acr"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('barc_config.acr')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                            id="acr"
                                                            v-model="scoreSetupData.acr"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Selection Committee" vid="selection_committee" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="selection_committee"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('barc_config.selection_committee')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        id="selection_committee"
                                                        v-model="scoreSetupData.selection_committee"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Total Required Score" vid="total_score" rules="required|max_value:100">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="total_score"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('barc_config.total_required_score')}}
                                                        </template>
                                                        <b-form-input
                                                        id="total_score"
                                                        v-model="totScore"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        disabled
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button type="submit" :disabled="isDisable" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                &nbsp;
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { scoreSetupStore, scoreSetupUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        if (this.id) {
            const tmp = this.getEditingData()
            this.scoreSetupData = tmp
        }
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            scoreSetupData: {
                service_length: '',
                academic_records: '',
                age: '',
                publications: '',
                foundation_training: '',
                acr: '',
                selection_committee: '',
                total_score: '',
                fiscal_year_id: 0
            },
            districtList: [],
            university_type: [
                {
                    value: 1,
                    text: 'Local',
                    text_bn: 'স্থানীয়'
                },
                {
                    value: 1,
                    text: 'Foreign',
                    text_bn: 'বিদেশী'
                }
            ],
            totScore: 0,
            isDisable: false
        }
    },
    watch: {
        'scoreSetupData.university_type': function (newVal, oldVal) {
            this.countryList = this.getCountryList(newVal)
        },
        'scoreSetupData.academic_records': function () {
            this.totScore = this.calculationData()
        },
        'scoreSetupData.service_length': function () {
            this.totScore = this.calculationData()
        },
        'scoreSetupData.age': function () {
            this.totScore = this.calculationData()
        },
        'scoreSetupData.publications': function () {
            this.totScore = this.calculationData()
        },
        'scoreSetupData.foundation_training': function () {
            this.totScore = this.calculationData()
        },
        'scoreSetupData.acr': function () {
            this.totScore = this.calculationData()
        },
        'scoreSetupData.selection_committee': function () {
            this.totScore = this.calculationData()
        }
    },
    mounted () {
        core.index()
        flatpickr('#datepicker', {})
    },
    computed: {
        fiscalYearList: function () {
            const fiscalYearList = this.$store.state.commonObj.fiscalYearList
            return fiscalYearList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        }
    },
    methods: {
        calculationData () {
            const totalVal = parseInt(this.scoreSetupData.academic_records) + parseInt(this.scoreSetupData.service_length) + parseInt(this.scoreSetupData.age) + parseInt(this.scoreSetupData.publications) + parseInt(this.scoreSetupData.foundation_training) + parseInt(this.scoreSetupData.acr) + parseInt(this.scoreSetupData.selection_committee)
            if (totalVal > 100) {
                this.isDisable = true
            } else {
                this.isDisable = false
            }
            return totalVal
        },
        getCountryList (universityTypeId) {
            const dataList = this.$store.state.commonObj.countryList.filter(item => item.status === 0)
            return dataList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        },
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }

            if (this.id) {
                result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${scoreSetupUpdate}/${this.id}`, this.scoreSetupData)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, scoreSetupStore, this.scoreSetupData)
            }
            loadingState.listReload = true

            if (result.warning) {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: this.$t('barc_config.max_value_warning'),
                    color: '#fff000'
                })
                return
            }

            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
    }
}
</script>

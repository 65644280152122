<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row class="mt-2">
                        <b-col lg="12" sm="12">
                            <div>
                            <b-table striped small bordered hover :items="columns"  thead-class="hidden_header"></b-table>
                            </div>
                        </b-col>
                    </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.scoreData = this.item
  },
  data () {
    return {
        scoreData: {}
    }
  },
  computed: {
    columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('barc_config.academic_records'), val: this.$n(this.scoreData.academic_records), key1: this.$t('barc_config.service_length'), val1: this.$n(this.scoreData.service_length) },
          { key: this.$t('barc_config.age'), val: this.$n(this.scoreData.age), key1: this.$t('barc_config.publications'), val1: this.$n(this.scoreData.publications) },
          { key: this.$t('barc_config.foundation_training'), val: this.$n(this.scoreData.foundation_training), key1: this.$t('barc_config.acr'), val1: this.$n(this.scoreData.acr) },
          { key: this.$t('barc_config.selection_committee'), val: this.$n(this.scoreData.selection_committee), key1: this.$t('barc_config.total_required_score'), val1: this.$n(this.scoreData.total_score) }
        ]
      } else {
        return [
          { key: this.$t('barc_config.academic_records'), val: this.scoreData.academic_records, key1: this.$t('barc_config.service_length'), val1: this.scoreData.service_length },
          { key: this.$t('barc_config.age'), val: this.scoreData.age, key1: this.$t('barc_config.publications'), val1: this.scoreData.publications },
          { key: this.$t('barc_config.foundation_training'), val: this.scoreData.foundation_training, key1: this.$t('barc_config.acr'), val1: this.scoreData.acr },
          { key: this.$t('barc_config.selection_committee'), val: this.$n(this.scoreData.selection_committee, { useGrouping: false }), key1: this.$t('barc_config.total_required_score'), val1: this.scoreData.total_score }
        ]
      }
    }
  }
}
</script>
<style lang="scss">
  .details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
